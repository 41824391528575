// ================================================================================================
//     File Name: coming-soon.scss
//     Description: Coming Soon pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy React Admin Template
//     Version: 1.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

/*========== Coming Soon Background Image =========*/

.clockCard {
  float: left;
}

.getting-started {
  font-size: 3rem;
}
.lead {
  font-size: 1rem;
}
