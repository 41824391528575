// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../core/variables/components-variables";

/* Swiper css */
/* ---------- */
/* swiper slide shadow */
.swiper-container {
  .swiper-shadow {
    box-shadow: 2px 8px 10px 0 rgba(25, 42, 70, 0.13) !important;
  }
}
.swiper-button-next,
.swiper-button-prev {
  &:after {
    font-size: 1.5rem;
  }
}
// centered slides option-1
.centered-style-1 {
  padding-bottom: 1rem;
  &.swiper-container {
    .swiper-slide {
      text-align: center;
      font-weight: $headings-font-weight;
      background-color: $white;
      height: auto;
      width: auto !important;
      padding: 2rem 5.5rem;
      cursor: pointer;

      &.swiper-slide-active {
        border: 2px solid $primary;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 30px;
    width: 30px;
    background-color: $primary;
    border-radius: 50%;
    &:after {
      font-size: 0.85rem;
    }
  }
}

// centered slides option-2
.centered-style-2 {
  padding-bottom: 1rem;
  &.swiper-container {
    .swiper-slide {
      font-weight: $headings-font-weight;
      background-color: $swiper-bg;
      height: auto;
      width: auto !important;
      padding: 1rem 3.5rem;
      cursor: pointer;

      &.swiper-slide-active {
        color: $white;
        background-color: $primary;
        box-shadow: 0 3px 6px 0 rgba($primary, 0.5) !important;
      }
    }
  }
}

/* Multi Row */
.swiper-container-multirow {
  height: auto;
  width: 100%;
  .swiper-wrapper {
    flex-direction: row;
  }
  .swiper-slide {
    height: calc((100% - 30px) / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}

/* cube effect */
.swiper-container-cube {
  &.swiper-container,
  .swiper-slide {
    height: 20rem;
    width: 20rem !important;
  }
}

/* swiper coverflow slide width */
.swiper-container-coverflow {
  &.swiper-container {
    .swiper-slide {
      width: 300px;
      height: 300px;
    }
  }
}

.swiper-gallery {
  .swiper-container:first-child {
    width: 100%;
    height: 35rem;
  }
  .swiper-container:last-child {
    padding: 10px 0;
    background: $black;
    height: 150px;
    .swiper-slide {
      width: 25%;
      height: 125px !important;
    }
  }
}

// parallax
.swiper-parallax {
  height: 20rem;
  .swiper-slide {
    padding: 2.67rem 4rem;

    .title {
      font-size: $h5-font-size;
      padding: 0.5rem 0;
    }

    .text {
      font-size: $font-size-sm;
    }
  }
  .parallax-bg {
    background: url("../../../img/slider/parallax-4.jpg");
    position: absolute;
    width: 130%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
 
}
.customc {
  background: url("../../../img/slider/main.jpg");
  height: 400px;
  // background: url("bkg2.png");
  background-size: auto 100vh;
  background-repeat: repeat-x;
  width: 400vw;
}
.customd {
  display: flex;
  flex-direction: row;
  width: 400vw;
  /* transform: rotate(90deg) translateY(-100vh); */
  /* transform-origin: top left; */
}
//navigation button custom boxicons
.swiper-button-prev,
.swiper-button-next {
  background-image: none;
  color: $white;
  width: 35px;
  font-size: $h1-font-size;
}

// Media query for medium screen
@media only screen and (max-width: 768px) {
  //navigation button custom boxicons
  .swiper-button-prev {
    font-size: $h4-font-size;
    top: 55%;
  }

  .swiper-button-next {
    font-size: $h4-font-size;
    top: 55%;
    width: 15px;
  }
}
